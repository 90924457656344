import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectListObject } from 'src/app/shared/models';
import { FeedbackService, ProjectsService } from 'src/app/shared/services';

@Component({
  selector: 'app-projects',
  templateUrl: './projects-overview.component.html',
})
export class ProjectsOverviewComponent {
  displayedColumns: string[] = ['name', 'customerName', 'startDate', 'endDate', 'workFromHomeAllowed', 'edit'];

  constructor(
    public projectsService: ProjectsService,
    public dialog: MatDialog,
    public feedback: FeedbackService,
    private router: Router,
  ) {}

  addProject(): void {
    this.router.navigate(['admin', 'project']);
  }

  editProject(row: ProjectListObject): void {
    this.router.navigate(['/admin/project', row.id]);
  }

  archiveProject(row: ProjectListObject): void {
    this.feedback.getArchiveConfirmation('Project ' + row.name).subscribe((dialogResult) => {
      if (dialogResult) {
        this._archiveProject(row.id);
      }
    });
  }

  restoreProject(row: ProjectListObject): void {
    this.feedback.getRestoreConfirmation('Project ' + row.name).subscribe((dialogResult) => {
      if (dialogResult) {
        this._restoreProject(row.id);
      }
    });
  }

  private _archiveProject(id) {
    this.projectsService.archive(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('Project is gearchiveerd');
        this.router.navigate(['admin', 'projecten']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  private _restoreProject(id) {
    this.projectsService.restore(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('Project is hersteld');
        this.router.navigate(['admin', 'projecten']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }
}
