<app-ura-overview
  [entityType]="'employee'"
  [dataObservable]="employeeService.getAll()"
  [archiveDataObservable]="employeeService.getArchived()"
  [displayedFields]="displayedColumns"
  [useArchiving]="true"
  (add)="addEmployee()"
  (edit)="editEmployee($event)"
  (archive)="archiveEmployee($event)"
  (restore)="restoreEmployee($event)"
  (overview)="linkHourOverview($event)"
>
</app-ura-overview>
