<div id="ura-overview">
  <h2><span translate>{{displayName}}</span> overzicht <span *ngIf="showArchiveView">(archief)</span></h2>

  <div class="header-wrapper">
    <mat-form-field class="mat-mdc-form-field" subscriptSizing="dynamic">
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="Filter"
        [formControl]="filterControl"
        [matAutocomplete]="auto"
        trim="blur"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let row of filteredOptions | async" [value]="row"> {{ row }} </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button
      *ngIf="showArchiveButton && !showArchiveView"
      mat-raised-button
      color="primary"
      class="mw-archive-button"
      (click)="onShowArchive()"
    >
      <mat-icon class="archive-icon">archive</mat-icon>
      <span translate>overview.archive</span>
    </button>
    <button
      *ngIf="showArchiveButton && showArchiveView"
      mat-raised-button
      color="primary"
      class="mw-archive-button"
      (click)="onShowArchive()"
    >
      <mat-icon class="archive-icon">backspace</mat-icon>
      <span translate>overview.return</span>
    </button>

    <button *ngIf="showAddButton" mat-raised-button color="primary" class="mw-add-button" (click)="onAdd()">
      <mat-icon class="add-icon">add_circle</mat-icon>
      <span class="translate-span" translate>{{displayName}}</span> toevoegen
    </button>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">
      <div *ngFor="let item of displayedFields; let i = index">
        <ng-container *ngIf="item !== 'edit' && item !== 'hoursOverview'" matColumnDef="{{item}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ura-{{entityType}}-{{item}}">
            <span translate>{{item}}</span>
          </th>
          <td mat-cell *matCellDef="let row" class="ura-{{entityType}}-{{item}}">
            <span *ngIf="isDateType(row[item])">{{row[item] | date}}</span>
            <span *ngIf="isBooleanType(row[item])">{{row[item].toString() | translate}}</span>
            <span *ngIf="!isDateType(row[item]) && !isBooleanType(row[item])">{{row[item]}}</span>
          </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container *ngIf="item ==='edit'" matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>{{ "options" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button *ngIf="canEdit()" color="primary" (click)="onEdit(row)">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="canArchive()"
              color="primary"
              (click)="onArchive(row)"
              [disabled]="!isArchivable(row)"
            >
              <mat-icon>archive</mat-icon>
            </button>
            <button mat-icon-button *ngIf="canRestore()" color="primary" (click)="onRestore(row)">
              <mat-icon>restore</mat-icon>
            </button>
            <button mat-icon-button *ngIf="canDelete()" color="primary" (click)="onDelete(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Urenoverzicht Column -->
        <ng-container *ngIf="item ==='hoursOverview'" matColumnDef="hoursOverview">
          <th mat-header-cell *matHeaderCellDef>Urenoverzicht</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button color="primary" (click)="onOverview(row)">
              <mat-icon>recent_actors</mat-icon>
            </button>
          </td>
        </ng-container>
      </div>

      <tr mat-header-row *matHeaderRowDef="displayedFields"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedFields"></tr>
    </table>

    <div *ngIf="error" style="margin: 1rem">Er was een fout bij het ophalen.</div>

    <div *ngIf="!error && !dataSource?.filteredData.length && !dataSource?.filter" style="margin: 1rem">
      Geen resultaten.
    </div>

    <div *ngIf="!error && dataSource?.filter && !dataSource?.filteredData.length" style="margin: 1rem">
      Geen resultaten uit de filter.
    </div>

    <mat-paginator [length]="dataSource.data.length" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
  </div>
</div>
