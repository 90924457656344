<app-ura-overview
  [entityType]="'project'"
  [dataObservable]="projectsService.getAll()"
  [archiveDataObservable]="projectsService.getArchived()"
  [displayedFields]="displayedColumns"
  [useArchiving]="true"
  (add)="addProject()"
  (edit)="editProject($event)"
  (archive)="archiveProject($event)"
  (restore)="restoreProject($event)"
>
</app-ura-overview>
