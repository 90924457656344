import { Routes } from '@angular/router';

import { HoursOverviewComponent } from './hours-overview/hours-overview/hours-overview.component';
import { AssignmentOverviewComponent } from './assignment/assignment-overview/assignment-overview.component';
import { EmployeesOverviewComponent } from './employee/employees-overview/employees-overview.component';
import { ProjectsOverviewComponent } from './projects/projects-overview/projects-overview.component';
import { EmployeeAddComponent } from './employee/employee-add/employee-add.component';
import { EmployeeEditComponent } from './employee/employee-edit/employee-edit.component';
import { HoursOverviewDetailComponent } from './hours-overview/hours-overview-detail/hours-overview-detail.component';
import { CustomersOverviewComponent } from './customers/customers-overview/customers-overview.component';
import { AssignmentDetailsComponent } from './assignment/assignment-details/assignment-details.component';
import { ProjectAddComponent } from './projects/project-add/project-add.component';
import { ProjectEditComponent } from './projects/project-edit/project-edit.component';
import { CustomerAddComponent } from './customers/customer-add/customer-add.component';
import { CustomerEditComponent } from './customers/customer-edit/customer-edit.component';

export const adminRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'medewerkers',
        component: EmployeesOverviewComponent,
        runGuardsAndResolvers: 'always',
        data: {
          icon: 'group',
          menuTranslateKey: 'menu.employees',
        },
      },
      {
        path: 'medewerker',
        component: EmployeeAddComponent,
      },
      {
        path: 'medewerker/:id',
        component: EmployeeEditComponent,
      },
      {
        path: 'klanten',
        component: CustomersOverviewComponent,
        data: {
          icon: 'contacts',
          menuTranslateKey: 'menu.customers',
        },
      },
      {
        path: 'klant',
        component: CustomerAddComponent,
      },
      {
        path: 'klant/:id',
        component: CustomerEditComponent,
      },
      {
        path: 'projecten',
        component: ProjectsOverviewComponent,
        data: {
          icon: 'assignment',
          menuTranslateKey: 'menu.projects',
        },
      },
      {
        path: 'project',
        component: ProjectAddComponent,
      },
      {
        path: 'project/:id',
        component: ProjectEditComponent,
      },
      {
        path: 'inzetten',
        component: AssignmentOverviewComponent,
        data: {
          icon: 'label',
          menuTranslateKey: 'menu.assignments',
        },
      },
      {
        path: 'inzet',
        component: AssignmentDetailsComponent,
      },
      {
        path: 'inzet/:id',
        component: AssignmentDetailsComponent,
      },
      {
        path: 'urenoverzicht',
        component: HoursOverviewComponent,
        data: {
          icon: 'recent_actors',
          menuTranslateKey: 'menu.hours',
        },
      },
      {
        path: 'urenoverzicht/:id/:year/:month',
        component: HoursOverviewDetailComponent,
      },
    ],
  },
];
