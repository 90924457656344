import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Routes } from '@angular/router';

export interface MenuItem {
  icon: string;
  translateKey: string;
  path?: string;
  onClick?(): void;
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private readonly userService: UserService) {}

  public getMenuFromRoute(routes: Routes): MenuItem[] {
    return this.createMenuFromRoute(routes, '/', []);
  }

  private createMenuFromRoute(routes: Routes, routerLinkPath: string, userMenu: MenuItem[]): MenuItem[] {
    routes.forEach((route) => {
      const childRoutePath = this.createRouterLink(routerLinkPath, route.path);
      if (route.data?.icon) {
        userMenu.push({
          icon: route.data.icon,
          translateKey: route.data.menuTranslateKey,
          path: childRoutePath,
        });
      }
      //root or submodules where no data is specified or when we have data we should check
      if (
        (route.children && !route.data) ||
        (route.children && route.data && this.userService.hasRoles(route.data.roleNeeded))
      ) {
        this.createMenuFromRoute(route.children, childRoutePath, userMenu);
      }
    });
    return userMenu;
  }

  private createRouterLink(baseRouterLink: string, routePath: string): string {
    return baseRouterLink.endsWith('/') || routePath.startsWith('/')
      ? baseRouterLink + routePath
      : baseRouterLink + '/' + routePath;
  }
}
