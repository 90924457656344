import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { KeyUp } from 'src/app/shared/helper/keyup';
import { UraValidators } from 'src/app/shared/helper/ura-validators';
import { Customer, CustomerListObject, Project } from 'src/app/shared/models';
import { CustomersService, FeedbackService, ProjectsService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['../../general.component.scss'],
})
export class ProjectEditComponent implements OnInit, AfterViewInit {
  dirty = false;
  project: Project = new Project();
  // ViewChild for autofocus, as of 3-3-2020 the autofocus attribute in html is still broken and stops working after cancelling the form once
  // and then reentering te form, in order to keep it working this has been implemented
  // See: https://stackoverflow.com/questions/56394068/autofocus-not-working-after-remove-and-adding-again-to-the-dom
  @ViewChild('name', { static: true }) focusElement: ElementRef;
  contentForm: UntypedFormGroup;
  id: number;

  customers: CustomerListObject[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private feedback: FeedbackService,
    public projectsService: ProjectsService,
    public customersService: CustomersService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.get();
    });

    this.contentForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl('', [Validators.required]),
        klant: new UntypedFormControl('', [Validators.required]),
        startDate: new UntypedFormControl('', [Validators.required]),
        endDate: new UntypedFormControl('', []),
        workFromHomeAllowed: new UntypedFormControl(null, []),
      },
      { validators: UraValidators.dateRangeValidator },
    );
  }

  get(): void {
    this.projectsService.get(this.id).subscribe((c) => {
      this.importProject(c);
    });
  }

  private importProject(c: Project): void {
    this.project = c;
    this.getCustomers();
  }

  private loadProject(): void {
    this.contentForm.get('name').setValue(this.project.name);
    this.contentForm.get('klant').setValue(this.customers.find((c) => c.id === this.project.customerId));
    this.contentForm.get('startDate').setValue(this.project.startDateString);
    this.contentForm.get('endDate').setValue(this.project.endDateString);
    this.contentForm.get('workFromHomeAllowed').setValue(this.project.workFromHomeAllowed);
  }

  private setProject(): void {
    const customer = this.contentForm.get('klant').value;

    this.project.name = this.contentForm.get('name').value;
    this.project.customerId = this.contentForm.get('klant').value.id;
    this.project.customer = new Customer({ id: customer.id, name: customer.name });
    this.project.startDate = moment(this.contentForm.get('startDate').value);
    this.project.endDate = moment(this.contentForm.get('endDate').value);
    this.project.workFromHomeAllowed = this.contentForm.get('workFromHomeAllowed').value;
  }

  private getCustomers(): void {
    this.customersService.getOrFetchAll().subscribe((customers) => {
      this.customers = customers;

      this.loadProject();
    });
  }

  keyup(e: KeyboardEvent): void {
    if (KeyUp.charactersAndBackspace(e)) this.dirty = true;
  }

  submit(): void {
    this.setProject();

    this.projectsService.createOrUpdate(this.project).subscribe({
      next: () => {
        this.router.navigate(['admin', 'projecten']);
        this.feedback.openSuccessToast(this.translateService.instant('De wijzigingen zijn opgeslagen'));
      },
      error: (err) => {
        this.router.navigate(['admin', 'projecten']);
        this.feedback.openErrorToast(err);
      },
    });
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['admin', 'projecten']);
  }

  ngAfterViewInit(): void {
    this.focusElement.nativeElement.focus();
    this.changeDetector.detectChanges();
  }
}
