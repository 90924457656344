import { Injectable } from '@angular/core';
import {
  Configuration,
  Customer as CustomerDTO,
  CustomerListObject as CustomerListObjectDTO,
  CustomersService as Swagger,
} from 'generated-sources';
import { Observable } from 'rxjs';
import { Customer, CustomerListObject } from 'src/app/shared/models';
import { HttpService } from './http';
import { ResourceService } from './resource.service';

@Injectable()
export class CustomersService extends ResourceService<
  Customer,
  CustomerDTO,
  CustomerListObject,
  CustomerListObjectDTO
> {
  private readonly proxy: Swagger;

  protected get name(): string {
    return 'customer';
  }

  constructor(private http: HttpService) {
    super(http);
    this.proxy = new Swagger(http.asClient, this.backend, new Configuration());
  }

  create(model: Customer): Observable<Customer> {
    const dto = this.modelToDto(model);
    return this.proxy.createCustomer(dto).pipe(this.mapSingle);
  }

  get(id: number): Observable<Customer> {
    return this.proxy.getCustomerById(id).pipe(this.mapSingle);
  }

  getAll(): Observable<Array<CustomerListObject>> {
    return this.proxy.getAllCustomers().pipe(this.mapList);
  }

  getArchived(): Observable<Array<CustomerListObject>> {
    return this.proxy.getArchivedCustomers().pipe(this.mapList);
  }

  update(id: number, model: Customer): Observable<Customer> {
    const dto = this.modelToDto(model);
    return this.proxy.updateCustomer(dto, id).pipe(this.mapSingle);
  }

  archive(id: number): Observable<unknown> {
    return this.proxy.archiveCustomer(id);
  }

  restore(id: number): Observable<unknown> {
    return this.proxy.restoreCustomer(id);
  }

  protected modelToDto(customer: Customer): CustomerDTO {
    return {
      id: customer.id,
      name: customer.name,
    };
  }

  protected dtoToModel(dto: CustomerDTO): Customer {
    return new Customer(dto);
  }

  protected listDtoToModel(dto: CustomerListObject): CustomerListObject {
    return new CustomerListObject(dto);
  }
}
