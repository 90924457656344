<app-ura-overview
  [entityType]="'customer'"
  [dataObservable]="customersService.getAll()"
  [archiveDataObservable]="customersService.getArchived()"
  [displayedFields]="displayedColumns"
  [useArchiving]="true"
  (add)="addCustomer()"
  (edit)="editCustomer($event)"
  (archive)="archiveCustomer($event)"
  (restore)="restoreCustomer($event)"
>
</app-ura-overview>
