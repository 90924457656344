<app-ura-overview
  [entityType]="'menuItem'"
  [dataObservable]="customMenuItemsService.getMenuItemsList()"
  [showArchiveButton]="false"
  [displayedFields]="displayedColumns"
  (add)="addMenuItem()"
  (edit)="editMenuItem($event)"
  (delete)="confirmDialog($event)"
>
</app-ura-overview>
