import { DailyEffort } from './dailyeffort';
import { Project } from './project';
import { Employee } from './employee';
import { Model } from './model';
import moment, { Moment } from 'moment';
import { Assignment as AssignmentDTO, DailyEffort as DailyEffortDTO } from 'generated-sources';

export class Assignment extends Model {
  employeeId: number;
  projectId: number;

  hoursPerWeek: number;

  startDate: Moment;
  endDate: Moment;
  deletable: boolean;
  efforts?: DailyEffort[] = [];
  deleted: boolean;

  private _employee?: Employee;
  private _project?: Project;

  constructor(source?: AssignmentDTO) {
    super();
    // copy data
    if (source) {
      this.id = source.id;
      this.projectId = source.project.id;
      this.employeeId = source.employeeId;
      this.hoursPerWeek = source.hoursPerWeek;
      this.startDate = moment(source.startDate);
      this.endDate = source.endDate ? moment(source.endDate) : null;
      this.deletable = !!source.deletable;
      this.deleted = !!source.deleted;
      this.efforts = source.dailyEfforts?.map((e: DailyEffortDTO) => new DailyEffort(e)) || [];

      // copy existing relationships
      this._project = source.project ? new Project(source.project) : null;
    } else {
      this.startDate = moment();
    }
  }

  public get employee(): Employee {
    return this._employee;
  }

  public set employee(e: Employee) {
    this._employee = e;
    this.employeeId = e?.id;
  }

  public get project(): Project {
    return this._project;
  }

  public set project(p: Project) {
    this._project = p;
    this.projectId = p?.id;
  }

  public get name(): string {
    return this.project?.name;
  }

  public contains(effort: DailyEffort): boolean {
    return this.efforts.filter((e) => effort.id === e.id).length > 0;
  }

  public set(effort: DailyEffort): DailyEffort {
    for (const index in this.efforts) {
      if (this.efforts[index].id === effort.id) {
        // eslint-disable-next-line sonarjs/no-associative-arrays
        this.efforts[index] = effort;
        return effort;
      }
    }
    this.efforts.push(effort);
  }

  public get startDateString(): string {
    return Model.formatDate(this.startDate);
  }

  public get endDateString(): string {
    return this.endDate?.isValid() ? Model.formatDate(this.endDate) : null;
  }
}
