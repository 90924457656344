import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, zip } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UraValidators } from 'src/app/shared/helper/ura-validators';
import { Assignment, CustomerListObject, EmployeeListObject, ProjectListObject } from 'src/app/shared/models';
import {
  AssignmentsService,
  CustomersService,
  EmployeesService,
  FeedbackService,
  LocaleService,
  ProjectsService,
} from 'src/app/shared/services';

interface CustomerWithProjects {
  customer: CustomerListObject;
  projects?: ProjectListObject[];
}

@Component({
  selector: 'app-assignment-details',
  templateUrl: './assignment-details.component.html',
  styleUrls: ['../../general.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class AssignmentDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('employee', { static: false }) employeeSelect: MatSelect;
  @ViewChild('project', { static: false }) projectSelect: MatSelect;
  @ViewChild('employeeSelectSearch', { static: false }) employeeSelectSearch: MatSelectSearchComponent;
  @ViewChild('projectSelectSearch', { static: false }) projectSelectSearch: MatSelectSearchComponent;
  dirty = false;
  id: number;
  contentForm: UntypedFormGroup;
  assignment: Assignment;

  employees: EmployeeListObject[];
  customers: CustomerWithProjects[];

  employee: EmployeeListObject;
  project: ProjectListObject;

  public employeeFilter = new UntypedFormControl();
  public filteredEmployees: ReplaySubject<EmployeeListObject[]> = new ReplaySubject<EmployeeListObject[]>(1);

  public customerFilter: UntypedFormControl = new UntypedFormControl();
  public filteredCustomers: ReplaySubject<CustomerWithProjects[]> = new ReplaySubject<CustomerWithProjects[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly customersService: CustomersService,
    private readonly employeesService: EmployeesService,
    private readonly assignmentsService: AssignmentsService,
    private readonly projectsService: ProjectsService,
    private readonly feedback: FeedbackService,
    localeService: LocaleService,
    dateAdapter: DateAdapter<Moment>,
  ) {
    dateAdapter.setLocale(localeService.getLocale());
  }

  ngOnInit(): void {
    this.initForm();
    this.fetchModel();
    this.employeeFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterEmployees();
    });

    // listen for search field value changes
    this.customerFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCustomers();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private load(): void {
    this.contentForm.get('employee').setValue(this.employee);
    this.contentForm.get('project').setValue(this.project);
    this.contentForm.get('hoursPerWeek').setValue(this.assignment.hoursPerWeek);
    this.contentForm.get('startDate').setValue(this.assignment.startDateString);
    this.contentForm.get('endDate').setValue(this.assignment.endDateString);
  }

  private setAssignment(): void {
    this.assignment.project = this.contentForm.get('project').value;
    this.assignment.employee = this.contentForm.get('employee').value;
    this.assignment.hoursPerWeek = this.contentForm.get('hoursPerWeek').value;
    this.assignment.startDate = moment(this.contentForm.get('startDate').value);
    this.assignment.endDate = moment(this.contentForm.get('endDate').value);
  }

  private fetchModel(): void {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (!this.id) {
        this.getContext(null);
      } else {
        this.assignmentsService.fetch(this.id).subscribe((a) => {
          this.getContext(a);
        });
      }
    });
  }

  private initForm(): void {
    this.contentForm = new UntypedFormGroup(
      {
        employee: new UntypedFormControl('', [Validators.required]),
        project: new UntypedFormControl('', [Validators.required]),
        hoursPerWeek: new UntypedFormControl('', [Validators.required]),
        startDate: new UntypedFormControl('', [Validators.required]),
        endDate: new UntypedFormControl('', []),
      },
      { validators: UraValidators.dateRangeValidator },
    );
  }

  private getContext(a: Assignment | null): void {
    this.assignment = a || new Assignment();
    this.employeesService
      .getOrFetchAll()
      .pipe(
        tap((list) => {
          this.employee = list.find((e) => e.id === this.assignment.employeeId);
        }),
      )
      .subscribe((res) => {
        this.employees = res;
        this.filteredEmployees.next(res);
        this.load();
      });

    zip(this.projectsService.getOrFetchAll(), this.customersService.getOrFetchAll())
      .pipe(
        tap((list) => {
          this.project = this.assignment.project && list[0].find((p) => this.assignment.project.id === p.id);
        }),
        map(([projects, customers]) =>
          customers.map<CustomerWithProjects>((c) => {
            return { customer: c, projects: projects.filter((p) => p.customerId === c.id) };
          }),
        ),
      )
      .subscribe((res) => {
        this.customers = res;
        this.filteredCustomers.next(res);
        this.load();
      });
  }

  submit(): void {
    this.setAssignment();
    this.assignmentsService.createOrUpdate(this.assignment).subscribe({
      next: () => {
        this.feedback.openSuccessToast('De wijzigingen zijn opgeslagen');
        this.router.navigate(['admin', 'inzetten']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['admin', 'inzetten']);
  }

  public byId(a: { id: string }, b: { id: string }): boolean {
    return a === b || (a && b && a.id === b.id);
  }

  acceptNumericOnly(event: InputEvent): void {
    if (!/^\d*$/.test(event.data ?? '')) {
      event.preventDefault();
    }
    this.dirty = true;
  }

  onElementChange(): void {
    this.dirty = true;
  }

  protected filterEmployees(): void {
    if (!this.employees) {
      return;
    }
    let needle = this.employeeFilter.value;

    if (!needle) {
      this.filteredEmployees.next(this.employees.slice());
      return;
    } else {
      needle = needle.toLowerCase();
    }
    this.filteredEmployees.next(this.employees.filter((e) => e.name.toLowerCase().includes(needle)));
  }

  protected filterCustomers(): void {
    if (!this.customers) {
      return;
    }
    let search = this.customerFilter.value;
    if (!search) {
      this.filteredCustomers.next(this.customers.slice());
      return;
    }
    search = search.toLowerCase();
    const filtered = this.customers
      .map((cwp) => {
        if (cwp.customer.name.toLowerCase().indexOf(search) > -1) {
          return cwp;
        }
        const projects = cwp.projects.filter(
          (l) => l.customerId === cwp.customer.id && l.name.toLowerCase().indexOf(search) > -1,
        );
        return {
          customer: cwp.customer,
          projects: projects,
        };
      })
      .filter((cwp) => cwp.projects.length > 0);
    this.filteredCustomers.next(filtered);
  }
}
