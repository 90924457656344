import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomerListObject } from 'src/app/shared/models';
import { CustomersService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-customers',
  templateUrl: './customers-overview.component.html',
})
export class CustomersOverviewComponent {
  displayedColumns: string[] = ['name', 'edit'];

  constructor(
    public customersService: CustomersService,
    public dialog: MatDialog,
    private feedback: FeedbackService,
    private router: Router,
  ) {}

  addCustomer(): void {
    this.router.navigate(['admin', 'klant']);
  }

  editCustomer(row: CustomerListObject): void {
    this.router.navigate(['/admin/klant', row.id]);
  }

  archiveCustomer(row: CustomerListObject): void {
    this.feedback.getArchiveConfirmation('Klant ' + row.name).subscribe((dialogResult) => {
      if (dialogResult) {
        this._archiveCustomer(row.id);
      }
    });
  }

  restoreCustomer(row: CustomerListObject): void {
    this.feedback.getRestoreConfirmation('Klant ' + row.name).subscribe((dialogResult) => {
      if (dialogResult) {
        this._restoreCustomer(row.id);
      }
    });
  }

  private _archiveCustomer(id) {
    this.customersService.archive(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('Klant is gearchiveerd');
        this.router.navigate(['admin', 'klanten']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  private _restoreCustomer(id) {
    this.customersService.restore(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('Klant is hersteld');
        this.router.navigate(['admin', 'klanten']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }
}
