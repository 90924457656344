import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, zip } from 'rxjs';
import { Assignment, CustomerListObject, EmployeeListObject } from 'src/app/shared/models';
import { AssignmentsService, CustomersService, EmployeesService, FeedbackService } from 'src/app/shared/services';

interface AssignmentListObject {
  assignment: Assignment;
  customer: CustomerListObject;
  employee: EmployeeListObject;
}

enum AssignmentType {
  ACTIVE,
  STANDARD,
  EXPIRED,
  ARCHIVED,
}

@Component({
  selector: 'app-inzet',
  templateUrl: './assignment-overview.component.html',
  styleUrls: ['./assignment-overview.component.scss'],
})
export class AssignmentOverviewComponent implements OnInit, AfterViewInit {
  constructor(
    private assignmentsService: AssignmentsService,
    public dialog: MatDialog,
    private feedback: FeedbackService,
    private employeesService: EmployeesService,
    private customersService: CustomersService,
    private router: Router,
  ) {}
  displayedColumns: string[] = [
    'employee.lastName',
    'employee.firstName',
    'project.name',
    'customer.name',
    'assignment.startDate',
    'assignment.endDate',
    'edit',
  ];
  dataSource = new MatTableDataSource<AssignmentListObject>();

  assignments: AssignmentListObject[];

  error = false;
  filterControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  assignmentTypeFilter = AssignmentType.ACTIVE;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  static isExpiredAssignment(assignment: Assignment): boolean {
    const startDate = moment(assignment.startDate);
    const endDate = moment(assignment.endDate);
    const now = moment();
    return endDate.isValid() && !now.isBetween(startDate, endDate, 'days', '[]');
  }

  ngOnInit(): void {
    this.fetch();

    this.dataSource.filterPredicate = (item, str) => !!this._filterItem(item, str);

    this.dataSource.sortingDataAccessor = (item, property) => {
      return property.split('.').reduce((object, key) => (object ? object[key] : undefined), item);
    };

    this.triggerSort('employee.lastName');
  }

  get isArchiveShown(): boolean {
    return this.assignmentTypeFilter === AssignmentType.ARCHIVED;
  }

  private triggerSort(val: string) {
    this.sort.active = val;
    this.dataSource.sort = this.sort;
    const sortState: Sort = { active: val, direction: this.sort.direction === 'asc' ? 'desc' : 'asc' };
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  private _filter(value: string): string[] {
    const filterValue = value.trim().toLowerCase();
    const result = [];

    for (const item of this.dataSource.data) {
      const eligible = this._filterItem(item, filterValue);
      if (eligible) result.push(eligible);
    }

    return result;
  }

  private _filterItem(item: AssignmentListObject, filterValue: string) {
    if (item.employee && item.employee.name.toLowerCase().indexOf(filterValue) >= 0) {
      return item.employee.name;
    } else if (item.assignment.project.name.toLowerCase().indexOf(filterValue) >= 0) {
      return item.assignment.project.name;
    } else if (item.customer.name.toLowerCase().indexOf(filterValue) >= 0) {
      return item.customer.name;
    }
  }

  editAssignment(id: number): void {
    this.router.navigate(['admin', 'inzet', id]);
  }

  addAssignment(): void {
    this.router.navigate(['admin', 'inzet']);
  }

  onChangeAssignmentType(value: keyof typeof AssignmentType): void {
    this.assignmentTypeFilter = AssignmentType[value];
    this.triggerFilter();
  }

  private fetch(): void {
    zip(this.assignmentsService.getAllIncludingArchived(), this.employeesService.getAllIncludingArchived()).subscribe(
      ([assignments, employees]) => {
        const employeeById = Object.fromEntries(employees.map((e) => [e.id, e]));

        this.assignments = assignments.map(({ assignment, customer }) => ({
          assignment: assignment,
          customer: customer,
          employee: employeeById[assignment.employeeId],
        }));

        this.applyData();
      },
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    const val = filterValue.trim().toLowerCase();
    this.dataSource.filter = val;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.filteredOptions = new Observable<string[]>((observer) => {
      observer.next(this._filter(val));
    });
  }

  triggerFilter(): void {
    this.applyData();
  }

  private applyData() {
    this.dataSource.data = this.assignments.filter((alo) => {
      const isInternal = alo.customer.isInternal();
      const isExpired = AssignmentOverviewComponent.isExpiredAssignment(alo.assignment);
      const isArchived = alo.assignment.deleted;

      switch (this.assignmentTypeFilter) {
        case AssignmentType.ACTIVE:
          return !isInternal && !isExpired && !isArchived;
        case AssignmentType.STANDARD:
          return !isArchived && isInternal;
        case AssignmentType.EXPIRED:
          return !isArchived && isExpired;
        case AssignmentType.ARCHIVED:
          return isArchived;
      }
    });
  }

  isArchivable(row: AssignmentListObject): boolean {
    return row.assignment.deletable;
  }

  archiveAssignment(row: AssignmentListObject): void {
    this.feedback.getArchiveConfirmation(row.assignment.project.name).subscribe((dialogResult) => {
      if (dialogResult) {
        this._archiveAssignment(row.assignment.id);
      }
    });
  }

  private _archiveAssignment(id: number) {
    this.assignmentsService.delete(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('De inzet is gearchiveerd');
        const item = this.dataSource.data.find((alo) => alo.assignment.id === id);
        if (item) item.assignment.deleted = true;
        this.applyData();
      },
      error: (err) => {
        const item = this.dataSource.data.find((alo) => alo.assignment.id === id);
        if (item) item.assignment.deletable = false;
        this.feedback.openErrorToast(err);
      },
    });
  }

  restoreAssignment(row: AssignmentListObject): void {
    this.feedback.getRestoreConfirmation(row.assignment.project.name).subscribe((dialogResult) => {
      if (dialogResult) {
        this._restoreAssignment(row.assignment.id);
      }
    });
  }

  private _restoreAssignment(id: number) {
    this.assignmentsService.restore(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('De inzet is hersteld');
        const item = this.dataSource.data.find((alo) => alo.assignment.id === id);
        if (item) item.assignment.deleted = false;
        this.applyData();
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }
}
