import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { EmployeeListObject } from 'src/app/shared/models';
import { EmployeesService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-employees',
  templateUrl: './employees-overview.component.html',
})
export class EmployeesOverviewComponent {
  displayedColumns: string[] = ['lastName', 'firstName', 'userName', 'edit', 'hoursOverview'];

  constructor(
    public employeeService: EmployeesService,
    public dialog: MatDialog,
    public feedback: FeedbackService,
    private router: Router,
  ) {}

  addEmployee(): void {
    this.router.navigate(['admin', 'medewerker']);
  }

  editEmployee(row: EmployeeListObject): void {
    this.router.navigate(['/admin/medewerker', row.id]);
  }

  archiveEmployee(row: EmployeeListObject): void {
    this.feedback.getArchiveConfirmation('Medewerker ' + row.firstName).subscribe((dialogResult) => {
      if (dialogResult) {
        this._archiveEmployee(row.id);
      }
    });
  }

  restoreEmployee(row: EmployeeListObject): void {
    this.feedback.getRestoreConfirmation('Medewerker ' + row.firstName).subscribe((dialogResult) => {
      if (dialogResult) {
        this._restoreEmployee(row.id);
      }
    });
  }

  linkHourOverview(row: EmployeeListObject): void {
    const now = moment().subtract(1, 'months');
    this.router.navigate(['admin', 'urenoverzicht', row.id, now.year(), now.month() + 1]);
  }

  private _archiveEmployee(id) {
    this.employeeService.archive(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('Medewerker is gearchiveerd');
        this.router.navigate(['admin', 'medewerkers']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  private _restoreEmployee(id) {
    this.employeeService.restore(id).subscribe({
      next: () => {
        this.feedback.openSuccessToast('Medewerker is hersteld');
        this.router.navigate(['admin', 'medewerkers']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }
}
